import { Injectable, Inject, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface ITheme {
  name: string,
  baseColor?: string,
  isActive?: boolean
}

@Injectable()
export class ThemeService {
  public onThemeChange: EventEmitter<ITheme> = new EventEmitter();

  public themes: ITheme[]  = [
    {
      name: 'initial',
      baseColor: '#ffffff',
      isActive: false
    },
    {
      name: 'UGC01',
      baseColor: '#F34A4A',
      isActive: false
    },
    {
      name: 'carrot-navy-dark',
      baseColor: '#0081ff',
      isActive: false
    },
    {
      name: 'carrot-navy',
      baseColor: '#10174c',
      isActive: false
    },
  ];

  public activatedTheme: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  // Invoked in AppComponent and apply 'activatedTheme' on startup
  applyTheme( themeName: string) {

    this.activatedTheme = this.themes.find(t => t.name === themeName);
    this.flipActiveFlag(themeName);

    this.document.body.classList.add(`theme-${themeName}`);

  }

  changeTheme(prevTheme: string, themeName: string) {
    this.document.body.classList.remove(`theme-${prevTheme}`);
    this.document.body.classList.add(`theme-${themeName}`);
    this.flipActiveFlag(themeName);
    this.onThemeChange.emit(this.activatedTheme);
  }

  flipActiveFlag(themeName: string) {
    this.themes.forEach((t) => {
      t.isActive = false;
      if (t.name === themeName) {
        t.isActive = true;
        this.activatedTheme = t;
      }
    });
  }
}
