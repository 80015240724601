export const environment = {
  firebase: {
    apiKey: "AIzaSyBu9EUmv9xVci2fpE6kbZzAteEPxQKTFuo",
    authDomain: "qa-carrot-sso.firebaseapp.com",
    projectId: "qa-carrot-sso",
    storageBucket: "qa-carrot-sso.appspot.com",
    messagingSenderId: "291772823965",
    appId: "1:291772823965:web:9693ad4dfa770f28501774",
    measurementId: "G-Q19MG5K6CE"
  },
  production: true,
  name: "QA",
  useEmulators: false,
  apiBaseUrl: "",
  clientId: "2ekvgQWSjWnvG2Cv4KU4"
};
