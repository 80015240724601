export const emailRegex = /^[\w-+\.]+@([\w-]+\.)+[\w-]+$/

export const userFormValidationMessages = {
    firstname: [
      { type: 'required', message: 'First name is required.' },
      { type: 'maxlength', message: 'First name cannot exceed 50 characters.' }
    ],
    lastname: [
      { type: 'required', message: 'Last name is required.' },
      { type: 'maxlength', message: 'Last name cannot exceed 50 characters.' }
    ],
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Please enter a valid email address.' }
    ],
    userClient: [
      { type: 'required', message: 'App is required.' },
    ],
    userClientRole: [
      { type: 'required', message: 'Role is required.' },
    ],
    currentpassword: [
      { type: 'required', message: 'This field is required.' }
    ],
};