import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { SsoApiResponse } from '../model/shared';
import { formatApiUrl } from './utilities';
import { AdminConfig } from '../model/adminConfig';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: AdminConfig;

  constructor(private http: HttpClient) { }

  getAdminConfig(): Observable<AdminConfig> {
    if (this.config) {
      return of(this.config);
    } else {
      return this.http.get<SsoApiResponse<AdminConfig>>(formatApiUrl('/api/v1/admin/config')).pipe(
        tap(resp => this.config = resp.data),
        map(resp => resp.data)
      );
    }
  }
}
