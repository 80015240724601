<div class="header">
  <h1 class="title">{{ data.title }}</h1>
</div>
<div class="content">
  <div class="dialog-message" [innerHTML]="data.message"></div>
  <div [ngClass]="{'shown' : loaderShown}" class="loader-modal">
    <div class="loader-modal-inner"></div>
  </div>
  <div class="json-wrapper">
    <json-editor
      [options]="jsonOptions"
      [data]="jsonToEdit"
      (change)="jsonChange($event)"
    ></json-editor>
  </div>
</div>
<div class="actions">
  <button *ngIf="data.cancelText" class="button button-secondary" (click)="cancel()">{{ data.cancelText }}</button>
  <button *ngIf="data.confirmText" class="button button-primary" (click)="confirm()" [disabled]="saveButtonDisabled" [ngClass]="{'loading': saveButtonLoading}">{{ data.confirmText }}</button>
</div>
