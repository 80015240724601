import { NgModule } from "@angular/core";
import { sortArrayPipe } from "./sort-array.pipe";

@NgModule({
  imports: [],
  declarations: [ 
    sortArrayPipe
  ],
  exports: [
    sortArrayPipe
  ]
})
export class PipesModule{}
