import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, Observable, take } from 'rxjs';
import { JSONEditModalComponent } from '../components/modal/json-edit-modal/json-edit-modal.component';

@Injectable()

export class JSONEditDialogService {
  constructor(private dialog: MatDialog) { }

  dialogRef: MatDialogRef<JSONEditModalComponent>;

  public open(options: any) {
    this.dialogRef = this.dialog.open(JSONEditModalComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        jsonToEdit: options.jsonToEdit,
      },
      panelClass: 'json-edit-modal',
      autoFocus: false
    });
  }
  
  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map(res => {
        return res;
      })
    );
  }
}