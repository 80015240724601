import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// SERVICES
import { ThemeService } from './services/theme.service';
import { MaterialModule } from './material.module';
// import { MatDialogModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { ModalModule } from './components/modal/modal.module';
import { ConfirmationDialogService } from './services/confirmation-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { NotificationDialogService } from './services/notification-dialog.service';
import { PasswordCheckDirective } from './directives/password/PasswordCheckDirective';
import { ScrollToTopModule } from './components/scroll-to-top/scroll-to-top.module';
import { PipesModule } from './pipes/pipes.module';
import { JSONEditDialogService } from './services/json-edit-dialog.service';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    ModalModule,
    MatDialogModule,
    ScrollToTopModule,
    PipesModule
  ],
  declarations: [
    PasswordCheckDirective
  ],
  providers: [
    ThemeService,
    ConfirmationDialogService,
    NotificationDialogService,
    JSONEditDialogService
  ],
  exports: [
    MaterialModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    ModalModule,
    MatDialogModule,
    PasswordCheckDirective,
    ScrollToTopModule,
    PipesModule
  ]
})
export class SharedModule { }
